const Clarity = [
    {
        id: 0,
        name: 'FL',
        code: '0',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 1,
        name: 'IF',
        code: '1',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 2,
        name: 'VVS1',
        code: '2',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 3,
        name: 'VVS2',
        code: '3',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 4,
        name: 'VS1',
        code: '4',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 5,
        name: 'VS2',
        code: '5',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 6,
        name: 'SI1',
        code: '6',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 7,
        name: 'SI2',
        code: '7',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 8,
        name: 'SI3',
        code: '8',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 9,
        name: 'I1',
        code: '9',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 10,
        name: 'I2',
        code: '10',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    },
    {
        id: 11,
        name: 'I3',
        code: '11',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6oZPXqsMu09b8wmUS2yTgETVcPQbbmguyg&usqp=CAU',
    }
]

export default Clarity;