import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
// import './App.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-responsive-modal/styles.css';
import { Modal, Radio } from 'antd';
import { IoClose } from "react-icons/io5";
import { Oval, RotatingLines } from 'react-loader-spinner';

function Store() {

    const [heightRes, setHeightRes] = useState(false);
    const gridStyle = useMemo(() => ({ height: window.innerWidth >= 972 ? window.innerHeight * 0.886 : window.innerWidth >= 400 ? window.innerHeight * 0.76 : window.innerHeight * 0.78, width: '100%', marginTop: window.innerWidth <= 500 && '30px' }), []);
    const gridStyle1 = useMemo(() => ({ height: '500px', width: '100%', marginTop: "20px" }), []);
    const [loading, setLoading] = useState(false);
    const [gridKey, setGridKey] = useState(0);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [totalSize, setTotalSize] = useState(0);
    const [rowData, setRowData] = useState([]);
    const [stoneIdList, setStoneIdList] = useState('');
    const [avgRapPrice, setAvgRapPrice] = useState(0);
    const [avgDiscount, setAvgDiscount] = useState(0);
    const [avgRate, setAvgRat] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const navigate = useNavigate();
    const [selectedCertificateNos, setSelectedCertificateNos] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [isOpen, setIsModalOpen] = useState(false);
    const [isHoldOpen, setIsHoldModalOpen] = useState(false);
    const [detailes, setDetailes] = useState([]);
    const [radioValue, setRadioValue] = useState('yes');
    const [radioValueOther, setRadioValueOther] = useState('AMOUNT');
    const [holdFor, setHoldFor] = useState('');
    const [textValue, setTextValue] = useState('');
    const [amountValue, setAmountValue] = useState('');
    const [holdValue, setHoldValue] = useState([])
    const [showLoader, setShowLoader] = useState(false)

    console.log(radioValueOther, "radioValueOther");
    

    useEffect(() => {
        if (window.innerWidth >= 780) {
            setHeightRes(true)
        }
    }, [heightRes])

    useEffect(() => {
        const email = localStorage.getItem('USERNAME')
        const password = localStorage.getItem('PASSWORD')

        if (!email || !password) {
            navigate('/')
        }
    }, [])

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }

    // Hold modal
    function openModalHold() {
        if (selectedRowCount === 0) {
            toast.error('Please select stones', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        } else if (selectedRowCount > 5) {
            toast.error('You can do Maximum 5 stones at one time', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }
        setIsHoldModalOpen(true);
        handleGetDropdownValue();
    }
    function closeModalHold() {
        setIsHoldModalOpen(false);
        setRadioValue('yes')
        setRadioValueOther('AMOUNT')
        setTextValue('')
        setAmountValue('')
        setHoldFor('')
    }

    const CustomPriceRenderer = ({ value }) => {
        return (
            <div style={{ textDecoration: 'underline', color: 'blue', textAlign: 'center', cursor: "pointer" }}>
                {value}
            </div>
        );
    };

    async function openModalPrice(params) {
        const email = localStorage.getItem('USERNAME')
        const password = localStorage.getItem('PASSWORD')
        if (email && password) {
            try {
                const response = await fetch(`${process.env.REACT_APP_PRODUCTION_URL}/stockPrice`, {
                    method: 'POST',
                    body: JSON.stringify({
                        USERNAME: email,
                        REPORTNO: params?.data['Certificate No'],
                        PASSWORD: password
                    }),
                    headers: {
                        'Content-type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                if (data.success === true) {
                    setDetailes(data?.message)
                    setIsModalOpen(true);
                } else {
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            } catch (err) {
                console.error(err.message);
            }
        }
    }
    function closeModalPrice() {
        setIsModalOpen(false);
    }

    const CustomLinkRenderer = (props) => {
        const { colDef, value } = props;

        const handleLinkClick = () => {
            window.open(value, '_blank');
        };

        return (
            <a href='#' onClick={handleLinkClick}>
                {(colDef.headerName === 'Video' && value) ? <img
                    src='https://res.cloudinary.com/dhpvia1ae/image/upload/v1703745504/Video_mqyt64.png'
                    height={15}
                    className='flex flex-row justify-center'
                    width={20}
                /> : (colDef.headerName === 'Image' && value) ? <img
                    src='https://res.cloudinary.com/dhpvia1ae/image/upload/v1703745504/Image_5_dfaqmt.png'
                    height={17}
                    width={20}
                /> : (value && <img
                    src='https://res.cloudinary.com/dhpvia1ae/image/upload/v1703745504/Certificate_xnd4ym.png'
                    height={20}
                    width={20}
                />)}
            </a>
        );
    };


    const gridRef = React.createRef();

    const gridApi = useRef(null);

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const onCellClicked = (params) => {
        if (params.colDef.field === "PI_SHAPE") {
            const googleSearchUrl = `https://www.google.com`;
            window.open(googleSearchUrl, "_blank");
        }
    };

    useEffect(() => {
        const grid = document.querySelector('.ag-root-wrapper');
        if (grid) {
            const headerCells = grid.querySelectorAll('.ag-header-cell-label');
            headerCells.forEach(cell => {
                cell.style.textAlign = 'center';
            });
        }
    }, []);

    const [columnDefs, setColumnDefs] = useState([
        {
            checkboxSelection: true, // Enable checkbox selection
            headerCheckboxSelection: true, // Enable header checkbox selection
            headerCheckboxSelectionFilteredOnly: true, // Only show header checkbox when rows are filtered
            width: 50, // Set the width of the checkbox column
            suppressRowClickSelection: true,
            // pinned: 'left',
        },
        // {
        //     field: 'StockID',
        //     width: 130,
        //     headerName: 'StockID',
        //     filter: true,
        //     menuTabs: ['filterMenuTab'],
        //     filterParams: {
        //         height: 500, // Set the height of the filter pop-up
        //     },
        //     // pinned: 'left',
        // },
        {
            field: 'VendorID',
            headerName: 'VendorID',
            menuTabs: ['filterMenuTab'],
            filter: true,
            headerClass: "headerRenoAv",
            width: 120,
            cellStyle: { textAlign: 'center' },
            cellClass: 'excelAligment',
        },
        {
            field: 'Certificate No',
            headerName: 'ReportNo',
            menuTabs: ['filterMenuTab'],
            filter: true,
            headerClass: "headerRenoAv",
            width: 120,
            cellStyle: { textAlign: 'center' }, // Center-align the data
            // pinned: 'left',
            cellClass: 'excelAligmentReport',
        },
        {
            field: "Video URL",
            headerName: "Video",
            cellStyle: { textAlign: 'center', top: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
            menuTabs: ['filterMenuTab'],
            headerClass: "headerRenoAv2",
            width: 100,
            filter: true,
            cellClass: 'hyperlinks',
            onCellClicked: onCellClicked,
            cellRenderer: CustomLinkRenderer,
        },
        {
            field: "Lab",
            headerName: "Lab",
            menuTabs: ['filterMenuTab'],
            filter: true,
            headerClass: "headerRenoAv1",
            width: 90,
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
        },
        {
            field: 'Shape',
            headerName: 'Shape',
            menuTabs: ['filterMenuTab'],
            width: 120,
            headerClass: "headerRenoAv",
            filter: true,
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
        },
        {
            field: "Size",
            headerName: "Size",
            menuTabs: ['filterMenuTab'],
            headerClass: "headerRenoAv1",
            width: 100,
            filter: true,
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'right' },
            valueFormatter: params => params?.data?.Size.toFixed(2)
        },
        {
            field: "Color",
            headerName: "Color",
            menuTabs: ['filterMenuTab'],
            headerClass: "headerRenoAv2",
            width: 100,
            filter: true,
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
        },
        {
            field: "Clarity",
            headerName: "Clarity",
            menuTabs: ['filterMenuTab'],
            headerClass: "headerRenoAv2",
            width: 110,
            wrapText: true,
            filter: true,
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
        },

        // {
        //     field: "RapAmt",
        //     width: 130,
        //     headerName: "RapAmt",
        //     filter: true,
        // },
        {
            field: "Cut",
            headerName: "Cut",
            menuTabs: ['filterMenuTab'],
            headerClass: "headerRenoAv1",
            width: 90,
            filter: true,
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
        },
        {
            field: "Polish",
            headerName: "Pol",
            menuTabs: ['filterMenuTab'],
            headerClass: "headerRenoAv1",
            filter: true,
            width: 90,
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
        },
        {
            field: "Sym",
            headerName: "Sym",
            menuTabs: ['filterMenuTab'],
            width: 90,
            headerClass: "headerRenoAv1",
            filter: true,
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
        },
        {
            field: "Floro",
            headerName: "Flo",
            menuTabs: ['filterMenuTab'],
            headerClass: "headerRenoAv1",
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
            width: 90,
            filter: true,
        },
        {
            field: "RapPrice",
            headerName: "Rap",
            menuTabs: ['filterMenuTab'],
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'right' },
            headerClass: "headerRenoAv3",
            width: 120,
            filter: true,
        },
        {
            field: "Dis",
            headerName: "Dis",
            menuTabs: ['filterMenuTab'],
            headerClass: "headerRenoAv1",
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'right' },
            width: 90,
            filter: true,
            valueFormatter: params => params?.data?.Dis.toFixed(2)
        },
        {
            field: "Rate",
            headerName: "$/CT",
            menuTabs: ['filterMenuTab'],
            headerClass: "headerRenoAv2",
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'right' },
            width: 100,
            filter: true,
            valueFormatter: params => params?.data?.Rate.toFixed(2)
        },
        {
            field: "Amount",
            headerName: "NET $",
            menuTabs: ['filterMenuTab'],
            cellStyle: { textAlign: 'right' },
            cellClass: 'excelAligment',
            headerClass: "headerRenoAv3",
            width: 120,
            filter: true,
            valueFormatter: params => params?.data?.Amount.toFixed(2)
        },
        {
            field: "COMP",
            headerName: "",
            menuTabs: ['filterMenuTab'],
            cellStyle: { textAlign: 'center' },
            headerClass: "headerRenoAv",
            width: 80,
            filter: true,
            onCellClicked: openModalPrice,
            cellClass: 'excelAligment',
            cellRenderer: CustomPriceRenderer,
        },
        {
            field: "Measurement",
            headerName: "Measurement",
            menuTabs: ['filterMenuTab'],
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
            headerClass: 'header',
            width: 140,
            filter: true,
        },
        {
            field: "Depth",
            headerName: "Depth",
            menuTabs: ['filterMenuTab'],
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
            headerClass: "headerRenoAv2",
            width: 100,
            filter: true,
            valueFormatter: params => params?.data?.Depth.toFixed(2)
        },
        {
            field: "Table",
            headerName: "Table",
            menuTabs: ['filterMenuTab'],
            headerClass: "headerRenoAv2",
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
            width: 100,
            filter: true,
            valueFormatter: params => params?.data?.Table.toFixed(2)
        },
        {
            field: "Ratio",
            headerName: "Ratio",
            menuTabs: ['filterMenuTab'],
            headerClass: "headerRenoAv2",
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
            width: 100,
            filter: true,
            valueFormatter: params => params?.data?.Ratio.toFixed(2)
        },
        {
            field: "Status",
            headerName: "Status",
            cellStyle: { textAlign: 'center' },
            menuTabs: ['filterMenuTab'],
            cellClass: 'excelAligment',
            headerClass: "headerRenoAv2",
            width: 100,
            filter: true,
        },
        {
            field: "Comment",
            headerName: "Type",
            cellStyle: { textAlign: 'center' },
            headerClass: "headerRenoAv2",
            cellClass: 'excelAligment',
            menuTabs: ['filterMenuTab'],
            width: 100,
            filter: true,
        },
        {
            field: "Image URL",
            headerName: "Image",
            menuTabs: ['filterMenuTab'],
            cellStyle: { textAlign: 'center', top: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
            headerClass: "headerRenoAv2",
            width: 110,
            filter: true,
            cellClass: 'hyperlinks',
            onCellClicked: onCellClicked,
            cellRenderer: CustomLinkRenderer,

        },
        {
            field: "Cert URL",
            headerName: "Cert",
            menuTabs: ['filterMenuTab'],
            cellStyle: { textAlign: 'center', top: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
            headerClass: "headerRenoAv2",
            cellClass: 'excelAligment',
            width: 100,
            filter: true,
            cellClass: 'hyperlinks',
            onCellClicked: onCellClicked,
            cellRenderer: CustomLinkRenderer,
        },
        {
            field: "Girdle",
            headerName: "Girdle",
            menuTabs: ['filterMenuTab'],
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
            headerClass: "headerRenoAv4",
            filter: true,
            width: 230
        },
        {
            field: "Culet",
            headerName: "Culet",
            menuTabs: ['filterMenuTab'],
            cellStyle: { textAlign: 'center' },
            cellClass: 'excelAligment',
            headerClass: "headerRenoAv2",
            width: 100,
            filter: true,
        },
        {
            field: "CAngle",
            headerName: "CAngle",
            menuTabs: ['filterMenuTab'],
            cellStyle: { textAlign: 'center' },
            headerClass: "headerRenoAv2",
            cellClass: 'excelAligment',
            width: 110,
            filter: true,
            valueFormatter: params => params?.data?.CAngle.toFixed(2)
        },
        {
            field: "CHeight",
            headerName: "CHeight",
            menuTabs: ['filterMenuTab'],
            headerClass: "headerRenoAv",
            cellStyle: { textAlign: 'center' },
            cellClass: 'excelAligment',
            width: 110,
            filter: true,
            valueFormatter: params => params?.data?.CHeight.toFixed(2)
        },
        {
            field: "PAngle",
            headerName: "PAngle",
            menuTabs: ['filterMenuTab'],
            cellStyle: { textAlign: 'center' },
            cellClass: 'excelAligment',
            headerClass: "headerRenoAv2",
            width: 110,
            filter: true,
            valueFormatter: params => params.data.PAngle.toFixed(2)
        },
        {
            field: "PDepth",
            headerName: "PDepth",
            menuTabs: ['filterMenuTab'],
            cellStyle: { textAlign: 'center' },
            cellClass: 'excelAligment',
            headerClass: "headerRenoAv2",
            width: 110,
            filter: true,
            valueFormatter: params => params?.data?.PDepth.toFixed(2)
        },
        {
            field: "SRNO",
            width: 130,
            cellClass: 'excelAligmentReport',
            headerName: "SRNO",
            menuTabs: ['filterMenuTab'],
            filter: true,
            hide: true,
        },
        {
            field: "RapAmt",
            width: 130,
            cellClass: 'excelAligment',
            headerName: "RapAmt",
            menuTabs: ['filterMenuTab'],
            filter: true,
            hide: true
        },
        {
            field: "Inscription",
            width: 130,
            headerName: "Inscription",
            cellClass: 'excelAligment',
            menuTabs: ['filterMenuTab'],
            filter: true,
            hide: true
        },
        {
            field: "SizeStep",
            width: 130,
            headerName: "Size Step",
            cellClass: 'excelAligment',
            menuTabs: ['filterMenuTab'],
            filter: true,
            hide: true
        },
        {
            field: "Fancy Intensity",
            width: 130,
            headerName: "Fancy Intensity",
            cellClass: 'excelAligment',
            menuTabs: ['filterMenuTab'],
            filter: true,
            hide: true
        },
        {
            field: "Fancy Overtone",
            width: 130,
            cellClass: 'excelAligment',
            headerName: "Fancy Overtone",
            menuTabs: ['filterMenuTab'],
            filter: true,
            hide: true
        },
        {
            field: "Fancy Color",
            width: 130,
            headerName: "Fancy Color",
            cellClass: 'excelAligment',
            menuTabs: ['filterMenuTab'],
            filter: true,
            hide: true
        },
        {
            field: "Length",
            width: 130,
            headerName: "Length",
            cellClass: 'excelAligment',
            menuTabs: ['filterMenuTab'],
            filter: true,
            hide: true
        }, {
            field: "Width",
            width: 130,
            headerName: "Width",
            cellClass: 'excelAligment',
            menuTabs: ['filterMenuTab'],
            filter: true,
            hide: true
        }, {
            field: "Height",
            width: 130,
            headerName: "Height",
            cellClass: 'excelAligment',
            menuTabs: ['filterMenuTab'],
            filter: true,
            hide: true
        },
        {
            field: "Location",
            headerName: "Location",
            menuTabs: ['filterMenuTab'],
            cellClass: 'excelAligment',
            cellStyle: { textAlign: 'center' },
            headerClass: 'header',
            width: 110,
            filter: true,
        },
    ]);

    useEffect(() => {
        const isVendor = localStorage.getItem('IS_VENDOR') === 'TRUE';
        const isClientPrice = localStorage.getItem('IS_CLIENT_PRICE') === 'TRUE';


        // Conditionally update columnDefs based on the value in localStorage
        if (!isVendor) {
            setColumnDefs((prevColumnDefs) =>
                prevColumnDefs.filter((colDef) => colDef.field !== 'VendorID')
            );
        }

        // Conditionally update columnDefs based on the value in localStorage
        if (!isClientPrice) {
            setColumnDefs((prevColumnDefs) =>
                prevColumnDefs.filter((colDef) => colDef.field !== 'COMP')
            );
        }
    }, []);

    const [columnDefs1, setColumnDefs1] = useState([

        // {
        //     field: 'Certificate No',
        //     headerName: 'ReportNo',
        //     menuTabs: [],
        //     filter: true,
        //     headerClass: "headerRenoAv",
        //     width: 120,
        //     cellStyle: { textAlign: 'center' }, // Center-align the data
        // },
        {
            field: "COMP",
            headerName: "Comp",
            menuTabs: [],
            cellStyle: { textAlign: 'left' },
            // headerClass: "headerRenoAv3",
            width: 80,
            filter: true,
        },
        // {
        //     field: "RapPrice",
        //     headerName: "Rap",
        //     menuTabs: [],
        //     cellStyle: { textAlign: 'right' },
        //     headerClass: "headerRenoAv2",
        //     width: 80,
        //     filter: true,
        // },
        {
            field: "Dis",
            headerName: "Dis",
            menuTabs: [],
            // headerClass: "headerRenoAv",
            cellStyle: { textAlign: 'left' },
            width: 90,
            filter: true,
            valueFormatter: params => params?.data?.Dis.toFixed(2)
        },
        {
            field: "Rate",
            headerName: "$/CT",
            menuTabs: [],
            // headerClass: "headerRenoAv2",
            cellStyle: { textAlign: 'left' },
            width: 90,
            filter: true,
            valueFormatter: params => params?.data?.Rate.toFixed(2)
        },
        {
            field: "Amount",
            headerName: "NET $",
            menuTabs: [],
            cellStyle: { textAlign: 'left' },
            // headerClass: "headerRenoAv",
            width: 100,
            filter: true,
            valueFormatter: params => params?.data?.Amount.toFixed(2)
        },
    ]);

    const handleSearch1 = async () => {
        const email = localStorage.getItem('USERNAME')
        const password = localStorage.getItem('PASSWORD')
        // SHAP-GET
        const shap = localStorage.getItem("Shape");
        const shapeValues = JSON.parse(shap);
        const shapeString = shapeValues.join('-');

        // SIZE-GET
        const size = localStorage.getItem("size");
        const sizeValues = JSON.parse(size);
        const sizeString = sizeValues.join('-');

        // SIZE-FROM
        const sizeFrom = localStorage.getItem("sizeFrom");
        const sizeFromValues = JSON.parse(sizeFrom);
        const sizeFromString = sizeFromValues

        // SIZE-TO
        const sizeTo = localStorage.getItem("sizeTo");
        const sizeToValues = JSON.parse(sizeTo);
        const sizeToString = sizeToValues

        // COLOR-
        const Color = localStorage.getItem("Color");
        const ColorValues = JSON.parse(Color);
        const ColorString = ColorValues.join('-');

        // CHECKED
        const Check = localStorage.getItem("Check");
        const CheckValues = JSON.parse(Check);
        const CheckString = CheckValues

        // FancyColor
        const FancyColor = localStorage.getItem("FancyColor");
        const FancyColorValues = FancyColor;
        const FancyColorString = FancyColorValues

        // FancyOvertone
        const FancyOvertone = localStorage.getItem("FancyOvertone");
        const FancyOvertoneValues = FancyOvertone;
        const FancyOvertoneString = FancyOvertoneValues

        // FancyIntensity
        const FancyIntensity = localStorage.getItem("FancyIntensity");
        const FancyIntensityValues = FancyIntensity;
        const FancyIntensityString = FancyIntensityValues

        // clarityCode
        const clarityCode = localStorage.getItem("clarityCode");
        const clarityCodeValues = JSON.parse(clarityCode);
        const clarityCodeString = clarityCodeValues.join('-')

        // Cut
        const Cut = localStorage.getItem("Cut");
        const CutValues = JSON.parse(Cut);
        const CutString = CutValues.join('-')

        // Polish
        const Polish = localStorage.getItem("Polish");
        const PolishValues = JSON.parse(Polish);
        const PolishString = PolishValues.join('-')

        // Symmetry
        const Symmetry = localStorage.getItem("Symmetry");
        const SymmetryValues = JSON.parse(Symmetry);
        const SymmetryString = SymmetryValues.join('-')

        // FluoreCode
        const FluoreCode = localStorage.getItem("FluoreCode");
        const FluoreCodeValues = JSON.parse(FluoreCode);
        const FluoreCodeString = FluoreCodeValues.join('-')

        // labCode
        const labCode = localStorage.getItem("labCode");
        const labCodeValues = JSON.parse(labCode);
        const labCodeString = labCodeValues.join('-')

        // ReportNo:
        const ReportNo = localStorage.getItem("ReportNo:");
        const ReportNoValues = JSON.parse(ReportNo);
        const ReportNoString = ReportNoValues

        // IDOptions:
        const IdOption = localStorage.getItem("reportNo");
        console.log(IdOption, "idoption");
        const IdOptionValues = IdOption;
        const IdOptionString = IdOptionValues

        // CHECKED
        const Location = localStorage.getItem("Location");
        const LocationValues = Location;
        const LocationString = LocationValues

        // CHECKED
        const LabGrown = localStorage.getItem("IS_LABGROWN");
        const LabGrownValues = LabGrown;
        const LabGrownString = LabGrownValues


        if (!email || !password) {
            navigate('/')
        }

        const url = new URL(`${process.env.REACT_APP_PRODUCTION_URL}/stockFill`);
        url.searchParams.append('USERNAME', email);
        url.searchParams.append('PASSWORD', password);
        url.searchParams.append('P_CODE', '');
        url.searchParams.append('STONEIDLIST', ReportNoString);
        url.searchParams.append('S_CODE', shapeString);
        url.searchParams.append('F_CARAT', parseFloat(sizeFromString) ? parseFloat(sizeFromString) : 0.00);
        url.searchParams.append('T_CARAT', parseFloat(sizeToString) ? parseFloat(sizeToString) : 0.00);
        url.searchParams.append('SZ_LIST', sizeString);
        url.searchParams.append('C_CODE', ColorString);
        url.searchParams.append('IS_FANCY', CheckString === true ? 'yes' : 'no');
        url.searchParams.append('FANCY_INTEN', FancyIntensityString);
        url.searchParams.append('FANCY_OVER', FancyOvertoneString);
        url.searchParams.append('FANCY_COLOR', FancyColorString);
        url.searchParams.append('Q_CODE', clarityCodeString);
        url.searchParams.append('CUT', CutString);
        url.searchParams.append('POL', PolishString);
        url.searchParams.append('SYM', SymmetryString);
        url.searchParams.append('FL_CODE', FluoreCodeString);
        url.searchParams.append('LAB_CODE', labCodeString);
        url.searchParams.append('LOCATION', LocationString);
        url.searchParams.append('IS_LABGROWN', LabGrownString);
        url.searchParams.append('STONE_TYPE', IdOptionString);

        setLoading(true);
        try {
            const response = await fetch(url.toString(), {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setRowData(data.data);
        } catch (err) {
            console.error(err.message);
        } finally {
            // Set loading to false after API call is complete
            setLoading(false);
        }
    };

    useEffect(() => {
        handleSearch1();
    }, []);

    const handleSearch = async () => {
        navigate('/search')
    };

    const handleReset = async () => {
        // Clear the input value
        setRowData([])
        setStoneIdList('')
    };

    const onSelectionChanged = () => {
        const selectedRows = gridApi.current.getSelectedRows();
        setSelectedRowCount(selectedRows?.length);

        const certificateNos = selectedRows?.map(row => row['Certificate No']);
        setSelectedCertificateNos(certificateNos);


        const sumSize = selectedRows?.reduce((total, row) => total + row.Size, 0);
        const totalRapAmt = selectedRows?.reduce((total, row) => total + row.RapAmt, 0);
        const totalAmount = selectedRows?.reduce((total, row) => total + row.Amount, 0);

        const avgRapPrice = sumSize === 0 ? 0 : totalRapAmt / sumSize;
        const avgRatPrice = sumSize === 0 ? 0 : totalAmount / sumSize;

        const avgDiscount = 100 - ((totalAmount / totalRapAmt) * 100)

        setAvgRapPrice(avgRapPrice);
        if (!isNaN(avgDiscount)) {
            setAvgDiscount(avgDiscount);
        } else {
            setAvgDiscount(0); // Set a default value if the calculation is not valid
        }
        setAvgRat(avgRatPrice);
        setTotalAmount(totalAmount);
        setTotalSize(sumSize)
    };

    // const handleQuillChange = (value) => {
    //     const sanitizedValue = value.replace(/\s+/g, ',');
    //     setStoneIdList(sanitizedValue);
    // };

    const defaultExcelExportParams = useMemo(() => {
        return {
            autoConvertFormulas: true,
            processCellCallback: params => {
                const field = params.column.getColDef().field;
                return field == 'Video URL' ? `=HYPERLINK("${params.value}")` : field == 'Image URL' ? `=HYPERLINK("${params.value}")` : field == 'Cert URL' ? `=HYPERLINK("${params.value}")` : params.value;
            }
        };
    }, []);

    const [formattedDateTime, setFormattedDateTime] = useState('');

    useEffect(() => {
        const getCurrentDateTime = () => {
            const currentDate = new Date();
            const day = String(currentDate.getDate()).padStart(2, '0');
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const year = String(currentDate.getFullYear());
            const hours = String(currentDate.getHours()).padStart(2, '0');
            const minutes = String(currentDate.getMinutes()).padStart(2, '0');
            const seconds = String(currentDate.getSeconds()).padStart(2, '0');

            const formattedDateTimeString = `${day}${month}${year}_${hours}${minutes}${seconds}`;
            setFormattedDateTime(formattedDateTimeString);
        };

        getCurrentDateTime();
    }, []);


    const handleExportToExcel = async () => {
        const rowData = gridRef.current.api.getModel().rowsToDisplay;

        if (rowData.length === 0) {
            // Handle the case when there is no data
            toast.error('No Data Found!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {
            const columnDefs = gridRef.current.api.getColumnDefs();

            // Specify individual column widths in the column definitions
            const updatedColumnDefs = columnDefs.map((column) => {
                switch (column.field) {
                    case 'VendorID':
                        return { ...column, width: 85 };
                    case 'Certificate No':
                        return { ...column, width: 85 };
                    case 'Video URL':
                        return { ...column, width: 470 };
                    case 'Lab':
                        return { ...column, width: 10 };
                    case 'Shape':
                        return { ...column, width: 25 };
                    case 'Size':
                        return { ...column, width: 15 };
                    case 'Color':
                        return { ...column, width: 10 };
                    case 'Clarity':
                        return { ...column, width: 10 };
                    case 'Cut':
                        return { ...column, width: 5 };
                    case 'Polish':
                        return { ...column, width: 5 };
                    case 'Sym':
                        return { ...column, width: 5 };
                    case 'Floro':
                        return { ...column, width: 10 };
                    case 'RapPrice':
                        return { ...column, width: 25 };
                    case 'Dis':
                        return { ...column, width: 15 };
                    case 'Rate':
                        return { ...column, width: 25 };
                    case 'Amount':
                        return { ...column, width: 30 };
                    case 'Length':
                        return { ...column, width: 10 };
                    case 'Width':
                        return { ...column, width: 10 };
                    case 'Height':
                        return { ...column, width: 10 };
                    case 'Depth':
                        return { ...column, width: 10 };
                    case 'Table':
                        return { ...column, width: 10 };
                    case 'Ratio':
                        return { ...column, width: 10 };
                    case 'Status':
                        return { ...column, width: 20 };
                    case 'Type':
                        return { ...column, width: 5 };
                    case 'Image URL':
                        return { ...column, width: 470 };
                    case 'Cert URL':
                        return { ...column, width: 370 };
                    case 'SRNO':
                        return { ...column, width: 3 };
                    case 'RapAmt':
                        return { ...column, width: 10 };
                    case 'Girdle':
                        return { ...column, width: 40 };
                    case 'Culet':
                        return { ...column, width: 25 };
                    case 'Inscription':
                        return { ...column, width: 30 };
                    case 'SizeStep':
                        return { ...column, width: 23 };
                    case 'Fancy Intensity':
                        return { ...column, width: 15 };
                    case 'Fancy Overtone':
                        return { ...column, width: 15 };
                    case 'Fancy Color':
                        return { ...column, width: 15 };
                    case 'Location':
                        return { ...column, width: 15 };
                    // Add other cases for your columns as needed
                    default:
                        return column;
                }
            });

            // Apply column definitions to the grid
            gridRef.current.api.setColumnDefs(updatedColumnDefs);

            // Export data
            const params = {
                columnGroups: true,
                skipFooters: true,
                skipGroups: true,
                skipPinnedTop: true,
                skipPinnedBottom: true,
                onlySelected: false,
                columnKeys: ['VendorID', 'Certificate No', 'Shape', 'Size', 'Color', 'Clarity', 'Cut', 'Polish', 'Sym', 'Floro', 'RapPrice', 'Dis', 'Rate', 'Amount', 'Length', 'Width', 'Height', 'Depth', 'Table', 'Ratio', 'Status', 'Comment', 'Lab', 'Video URL', 'Image URL', 'Cert URL', 'SRNO', 'RapAmt', 'Girdle', 'Culet', 'Inscription', 'SizeStep', 'Location', 'Fancy Intensity', 'Fancy Overtone', 'Fancy Color'],
                fileName: `StockList_${formattedDateTime}.xlsx`,
            };

            await gridRef.current.api.exportDataAsExcel(params);

            // Specify individual column widths in the column definitions
            const originalColumnDefs = columnDefs.map((column) => {
                switch (column.field) {
                    case 'VendorID':
                        return { ...column, width: 120 };
                    case 'Certificate No':
                        return { ...column, width: 120 };
                    case 'Video URL':
                        return { ...column, width: 100 };
                    case 'Lab':
                        return { ...column, width: 90 };
                    case 'Shape':
                        return { ...column, width: 120 };
                    case 'Size':
                        return { ...column, width: 100 };
                    case 'Color':
                        return { ...column, width: 100 };
                    case 'Clarity':
                        return { ...column, width: 110 };
                    case 'Cut':
                        return { ...column, width: 90 };
                    case 'Polish':
                        return { ...column, width: 90 };
                    case 'Sym':
                        return { ...column, width: 90 };
                    case 'Floro':
                        return { ...column, width: 90 };
                    case 'RapPrice':
                        return { ...column, width: 120 };
                    case 'Dis':
                        return { ...column, width: 90 };
                    case 'Rate':
                        return { ...column, width: 100 };
                    case 'Amount':
                        return { ...column, width: 120 };
                    case 'Length':
                        return { ...column, width: 30 };
                    case 'Width':
                        return { ...column, width: 30 };
                    case 'Height':
                        return { ...column, width: 30 };
                    case 'Depth':
                        return { ...column, width: 100 };
                    case 'Table':
                        return { ...column, width: 100 };
                    case 'Ratio':
                        return { ...column, width: 100 };
                    case 'Status':
                        return { ...column, width: 100 };
                    case 'Type':
                        return { ...column, width: 100 };
                    case 'Image URL':
                        return { ...column, width: 110 };
                    case 'Cert URL':
                        return { ...column, width: 100 };
                    case 'SRNO':
                        return { ...column, width: 30 };
                    case 'RapAmt':
                        return { ...column, width: 30 };
                    case 'Girdle':
                        return { ...column, width: 230 };
                    case 'Culet':
                        return { ...column, width: 100 };
                    case 'Inscription':
                        return { ...column, width: 30 };
                    case 'SizeStep':
                        return { ...column, width: 30 };
                    case 'Fancy Intensity':
                        return { ...column, width: 30 };
                    case 'Fancy Overtone':
                        return { ...column, width: 30 };
                    case 'Fancy Color':
                        return { ...column, width: 30 };
                    case 'Location':
                        return { ...column, width: 110 };
                    // Add other cases for your columns as needed
                    default:
                        return column;
                }
            });
            await gridRef.current.api.setColumnDefs(originalColumnDefs);
        }
    };

    const excelStyles = useMemo(() => {
        return [
            {
                id: 'headerRenoAv1',
                interior: {
                    color: '#C5D9F1',
                    pattern: 'Solid',
                },
                alignment: {
                    vertical: "Center",
                    horizontal: "Center",
                }
            },
            {
                id: 'headerRenoAv',
                interior: {
                    color: '#C5D9F1',
                    pattern: 'Solid',
                },
                alignment: {
                    vertical: "Center",
                    horizontal: "Center",
                }
            },
            {
                id: 'headerRenoAv2',
                interior: {
                    color: '#C5D9F1',
                    pattern: 'Solid',
                },
                alignment: {
                    vertical: "Center",
                    horizontal: "Center",
                }
            },
            {
                id: 'headerRenoAv3',
                interior: {
                    color: '#C5D9F1',
                    pattern: 'Solid',
                },
                alignment: {
                    vertical: "Center",
                    horizontal: "Center",
                }
            },
            {
                id: 'headerRenoAv4',
                interior: {
                    color: '#C5D9F1',
                    pattern: 'Solid',
                },
                alignment: {
                    vertical: "Center",
                    horizontal: "Center",
                }
            },
            {
                id: 'header',
                interior: {
                    color: '#C5D9F1',
                    pattern: 'Solid',
                },
                alignment: {
                    vertical: "Center",
                    horizontal: "Center",
                }
            },
            {
                id: 'hyperlinks',
                font: {
                    underline: 'Single',
                    color: '#358ccb',
                },
                alignment: {
                    vertical: "Center",
                    horizontal: "Center",
                }
            },
            {
                id: "excelAligment",
                alignment: {
                    vertical: "Center",
                    horizontal: "Center",
                },
                numberFormat: {
                    format: '#######0.00',
                },
            },
            {
                id: "excelAligmentReport",
                alignment: {
                    vertical: "Center",
                    horizontal: "Center",
                },
            },

        ];
    }, []);

    const handleLogOut = () => {
        localStorage.removeItem('USERNAME')
        localStorage.removeItem('PASSWORD')
        localStorage.removeItem('IS_HOLD')
        localStorage.removeItem('IS_CONFIRM')
        localStorage.removeItem('IS_CLIENT_PRICE')
        localStorage.removeItem('IS_VENDOR')
        navigate('/')
    };

    const handleHoldButton = async (value) => {
        const email = localStorage.getItem('USERNAME')
        if (selectedRowCount === 0) {
            toast.error('Please select stones', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (selectedRowCount > 5) {
            toast.error('You can do Maximum 5 stones at one time', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (value === 'no' && radioValue === 'yes' && holdFor === '') {
            toast.error("Please select Hold for", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        } else if (value === 'no' && radioValue === 'no' && textValue === '') {
            toast.error("Please enter Hold for", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        } else if (value === 'no' && radioValue === 'no' && amountValue === '' && selectedRowCount <= 1) {
            toast.error("Please enter Amount!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_PRODUCTION_URL}/holdItem`, {
                    method: 'POST',
                    body: JSON.stringify({
                        USERNAME: email,
                        STONEIDLIST: selectedCertificateNos.join(', '),
                        IS_REMOVE: value,
                        IS_CLIENT: radioValue,
                        HOLD_AMOUNT: parseFloat(amountValue),
                        HOLD_FOR: holdFor ? holdFor : textValue,
                        RATE_TYPE: radioValueOther
                    }),
                    headers: {
                        'Content-type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                if (data.success === 'TRUE') {
                    toast.success(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setAmountValue('')
                    setTextValue('')
                    setHoldFor('')
                    setIsHoldModalOpen(false)
                } else {
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            } catch (err) {
                console.error(err.message);
            }
        }
    }

    const handleConfirmButton = async () => {
        const email = localStorage.getItem('USERNAME')
        if (selectedRowCount === 0) {
            toast.error('Please select stones!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else if (selectedRowCount > 5) {
            toast.error('You can maximum 5 stones as one time!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_PRODUCTION_URL}/confirmItem`, {
                    method: 'POST',
                    body: JSON.stringify({
                        USERNAME: email,
                        STONEIDLIST: selectedCertificateNos.join(', '),
                    }),
                    headers: {
                        'Content-type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();

                if (data.success === 'TRUE') {
                    setRowData([]);
                    setStoneIdList('');
                    toast.success(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                } else {
                    toast.error(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            } catch (err) {
                console.error(err.message);
            }
        }
    }


    // useEffect(() => {
    //     const handleFocus = () => {
    //         // Adjust the position or scroll when the input is focused
    //         // You may need to customize this based on your specific layout
    //         window.scrollTo(0, 0); // Scroll to the top of the page as an example
    //     };

    //     const handleBlur = () => {
    //         // Handle any actions when the input loses focus
    //     };

    //     // const inputElement = document.getElementById('yourInputId'); 
    //     // inputElement.addEventListener('focus', handleFocus);
    //     // inputElement.addEventListener('blur', handleBlur);

    //     // Clean up event listeners when the component is unmounted
    //     return () => {
    //         inputElement.removeEventListener('focus', handleFocus);
    //         inputElement.removeEventListener('blur', handleBlur);
    //     };
    // }, []);

    const onRadioChange = (e) => {
        if (e.target.value === 'yes') {
            setTextValue('')
            setAmountValue('')
        } else {
            setHoldFor('')
        }
        setRadioValue(e.target.value);
    };

    const onRadioChangeOther = (e) => {
        setRadioValueOther(e.target.value);
    };

    const onTextChange = (e) => {
        setTextValue(e.target.value);
    };

    const onAmountChange = (e) => {
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,4}$/; // Regex to allow numbers with up to 4 decimal places
        if (regex.test(value)) {
            setAmountValue(value);
        }
    };


    const handleGetDropdownValue = async () => {
        const email = localStorage.getItem('USERNAME')
        const password = localStorage.getItem('PASSWORD')
        setShowLoader(true)
        await fetch(`${process.env.REACT_APP_PRODUCTION_URL}/hold/value`, {
            method: 'POST',
            body: JSON.stringify({
                USERNAME: email,
                PASSWORD: password
            }),
            headers: {
                'Content-type': 'application/json'
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setShowLoader(false)
                setHoldValue(data?.data);
                if (data.success === 'TRUE') {
                } else {
                    // toast.error(data.message, {
                    toast.error(data.message.MSG, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            })
            .catch((err) => {
                setShowLoader(false)
            });
    }
    return (
        <>
            <ToastContainer />
            {
                loading ?
                    <div className='m-auto flex flex-row justify-center items-center' style={{ height: "100vh" }}>
                        <RotatingLines
                            visible={true}
                            height="50"
                            width="50"
                            color="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                    :
                    <div className='p-2'>
                        <div class="header" style={{
                            height: window.innerWidth >= 972
                                ? window.innerHeight * 0.09
                                : (window.innerWidth >= 350 && localStorage.getItem('IS_HOLD') === 'TRUE')
                                    ? window.innerHeight * 0.285
                                    : window.innerHeight * 0.22
                        }}
                        >
                            <div class="search-bar">
                                <div className='flex flex-col md:flex-row gap-1 md:gap-[15px] '>
                                    <div class="button-container">
                                        <button className="buttonSearch !w-max !px-2" onClick={handleSearch}>
                                            Modify Search
                                        </button>
                                        {localStorage.getItem('IS_CONFIRM') === 'TRUE' &&
                                            <button onClick={handleConfirmButton} class='confirm-hover'>
                                                Confirm
                                            </button>
                                        }
                                    </div>
                                    <div className='button-container flex flex-row'>
                                        {localStorage.getItem('IS_HOLD') === 'TRUE' &&
                                            <button onClick={openModalHold} class='hold-hover'>
                                                Hold
                                            </button>
                                            /* onClick={() => { handleHoldButton('no') }} */
                                        }
                                        {localStorage.getItem('IS_HOLD') === 'TRUE' &&
                                            <button onClick={() => { handleHoldButton('yes') }} class='hold-hover'>
                                                UnHold
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div class="summary">
                                    <div class="summary-item">
                                        <span class='labelName'>Total: <b class='labelName' style={{ marginLeft: "5px" }}>{selectedRowCount}</b></span>
                                        <span class='labelName'>Carat: <b class='labelName' style={{ marginLeft: "5px" }}>{totalSize?.toFixed(2)}</b></span>
                                    </div>
                                    <div class="summary-item">
                                        <span class='labelName'>Rap: <b class='labelName' style={{ marginLeft: "5px" }}>{avgRapPrice?.toFixed(0)}</b></span>
                                        <span class='labelName'>Disc: <b class='labelName' style={{ marginLeft: "5px" }}>{avgDiscount === NaN ? 0 : avgDiscount?.toFixed(2)}</b></span>
                                    </div>
                                    <div class="summary-item">
                                        <span class='labelName'>$/CT: <b class='labelName' style={{ marginLeft: "5px" }}>{avgRate?.toFixed(0)}</b></span>
                                        <span class='labelName1'>NET $: <b class='labelName' style={{ marginLeft: "5px" }}>{totalAmount?.toFixed(0)}</b></span>
                                    </div>
                                </div>
                            </div>

                            <div className='button-logout'>
                                <button class="excel-hover" onClick={handleExportToExcel}>
                                    Excel
                                </button>
                                <button class="logout-hover" onClick={openModal}>
                                    Log Out
                                </button>
                            </div>
                        </div>
                        <div id="gridTable" style={gridStyle} class="ag-theme-alpine">
                            <AgGridReact
                                key={gridKey}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                ref={gridRef}
                                excelStyles={excelStyles}
                                onFirstDataRendered={onGridReady}
                                onSelectionChanged={onSelectionChanged}
                                defaultExcelExportParams={defaultExcelExportParams}
                                rowSelection='multiple'
                                suppressRowClickSelection={true}
                                overlayNoRowsTemplate={loading === true ? '<div class="ag-overlay-loading-center">Loading...</div>' : '<div class="ag-overlay-loading-center">No data to show</div>'}
                            ></AgGridReact>
                        </div>

                        <Modal open={modalIsOpen} footer={null} width={305} closable={false} onCancel={closeModal} centered>
                            <label style={{ width: "100%", textAlign: "center", marginLeft: "44px" }}>Are you sure want to Logout?</label>
                            <div style={{ display: "flex", justifyContent: "center", gap: "15px", marginTop: "10px" }}>
                                <button className='hold-hover' onClick={handleLogOut}>
                                    Yes
                                </button>
                                <button className='confirm-hover' onClick={closeModal}>
                                    No
                                </button>
                            </div>
                        </Modal>

                        <Modal destroyOnClose={true} open={isOpen} footer={null} width={355} closable={false} onCancel={closeModalPrice} onOk={false} centered>
                            <div style={{ display: "flex", justifyContent: "end", gap: "15px", marginTop: "10px" }}>
                                <IoClose style={{ position: "absolute", top: "10px", right: "18px" }} className='close-hover' onClick={closeModalPrice} size={20} />
                            </div>
                            <span style={{ fontWeight: "600", color: "black", fontFamily: "Poppins", }}>ReportNo: <b style={{ fontFamily: "Poppins", letterSpacing: "0.2px", color: "blue" }}>{detailes[0] && detailes[0]['Certificate No']}</b></span>
                            <div id="gridTable" style={gridStyle1} class="ag-theme-alpine">
                                <AgGridReact
                                    rowData={detailes}
                                    columnDefs={columnDefs1}
                                    overlayNoRowsTemplate={loading ? '<div class="ag-overlay-loading-center">Loading...</div>' : '<div class="ag-overlay-loading-center">No data to show</div>'}
                                ></AgGridReact>
                            </div>
                        </Modal>

                        {/* Hold Modal */}
                        <Modal destroyOnClose={true} open={isHoldOpen} footer={null} width={355} style={{ height: "180px !important" }} closable={false} onCancel={closeModalHold} onOk={false} centered>
                            <div style={{ display: "flex", justifyContent: "end", gap: "15px", marginTop: "10px" }}>
                                <IoClose style={{ position: "absolute", top: "10px", right: "18px" }} className='close-hover' onClick={closeModalHold} size={20} />
                            </div>

                            <div className="custom-modal-height" >
                                <div style={{ marginTop: "20px", justifyContent: "center", display: "flex" }}>
                                    <Radio.Group onChange={onRadioChange} value={radioValue}>
                                        <Radio value="yes" className='font-bold mr-5 text-[15px]'>Client</Radio>
                                        <Radio value="no" className='font-bold text-[15px]'>Other</Radio>
                                    </Radio.Group>
                                </div>
                                {
                                    radioValue === 'yes' &&
                                    <>
                                        <div className='flex mt-4 items-center'>
                                            <div className='colorname'>Hold For &nbsp;</div>
                                            <select onChange={(e) => {
                                                setHoldFor(e.target.value)
                                            }} style={{ width: "79%", outline: "none", borderRadius: "5px", height: "35px", padding: "7px", border: "1px solid black", }} value={holdFor} className={`border border-black bg-[#ffffff]`}>
                                                {/* <option value='' className='bg-gray-200' selected >Select</option> */}
                                                <option value='' className='bg-gray-200' selected >Select</option>
                                                {holdValue?.map((item, index) => (
                                                    <option key={index} value={item.SUPPCODE}>{item.SUPPCODE}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </>
                                }

                                {
                                    radioValue === 'no' &&
                                    <div className='mt-4'>
                                        <>
                                            <div className='flex items-center'>
                                                <div className='colorname'>Hold For &nbsp;</div>
                                                <input
                                                    type="text"
                                                    placeholder="Hold For"
                                                    onChange={onTextChange}
                                                    value={textValue}
                                                    style={{ outline: "none", borderRadius: "5px", padding: "6px", border: "1px solid black", width: "79%" }}

                                                />
                                            </div>
                                        </>
                                    </div>
                                }

                                {/* Amount Input */}
                                {
                                    (radioValue === 'no' && selectedRowCount <= 1) &&
                                    < div className=''>
                                        <>
                                            <div style={{ marginTop: "10px", justifyContent: "center", display: "flex" }}>
                                                <Radio.Group onChange={onRadioChangeOther} value={radioValueOther}>
                                                    <Radio value="AMOUNT" className='font-bold mr-5 text-[15px]'>Amount</Radio>
                                                    <Radio value="RATE" className='font-bold text-[15px]'>$/CT</Radio>
                                                </Radio.Group>
                                            </div>
                                            <div className='flex items-center'>
                                                <div className='colorname' style={{ width: "59.5px", textAlign:"end" }}>{radioValueOther === 'AMOUNT' ? 'Amount' : '$/CT'} &nbsp;</div>
                                                <input
                                                    type="number"
                                                    placeholder={radioValueOther === 'AMOUNT' ? 'Amount' : '$/CT'}
                                                    className='ml-1'
                                                    style={{ outline: "none", marginTop: "10px", borderRadius: "5px", padding: "6px", border: "1px solid black", width: "30%", textAlign: "right" }}
                                                    step="0.0001"
                                                    value={amountValue}
                                                    onChange={onAmountChange}
                                                />
                                            </div>
                                        </>
                                    </div>
                                }


                                <div className='flex flex-row justify-center absolute hold-button '>
                                    <button onClick={() => { handleHoldButton('no') }} class='hold-hover'>
                                        Hold
                                    </button>
                                </div>
                            </div>

                            {/* <span style={{ fontWeight: "600", color: "black", fontFamily: "Poppins", }}>ReportNo: <b style={{ fontFamily: "Poppins", letterSpacing: "0.2px", color: "blue" }}>{detailes[0] && detailes[0]['Certificate No']}</b></span>
                            <div id="gridTable" style={gridStyle1} class="ag-theme-alpine">
                               
                            </div> */}
                        </Modal>
                    </div>
            }
        </>
    );
};

export default Store;